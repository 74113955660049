<template>
    <div class="Lifesaving">
        <div class="Lifesaving_left">
          <treedata></treedata>
        </div>
        <div class="Lifesaving_right">
           <el-table
                    :data="tableData"
                    border
                    style="width: 100%"
                    height="700px">
                    <el-table-column
                        align="center"                      
                        prop="HospitalName"
                        label="单位"
                        width="280">
                    </el-table-column>
                    <el-table-column
                        align="center" 
                        prop="SerialNumber"
                        label="序号"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        align="center" 
                        prop="UserName"
                        label="姓名">
                    </el-table-column>
                     <el-table-column
                        label="图片"
                        align="center">
                        <template slot-scope="scope">
                                 <el-image 
                                  style="width: 80px; height: 80px;"
                                  fit='scale-down'
                                  :src="scope.row.SignatureBase64"
                                  :preview-src-list="srcList"
                                  @click="vbs(scope.row.SignatureBase64)">
                                </el-image>
                        </template>
                    </el-table-column>
                     <el-table-column
                        align="center" 
                        label="状态">
                       <template slot-scope="scope">
                              <span class="span1" v-show="scope.row.State==1?true:false"></span>
                              <span class="span2" v-show="scope.row.State==0?true:false"></span>
                              <span v-show="scope.row.State==1?true:false">已签署</span>
                              <span v-show="scope.row.State==0?true:false">已驳回</span>
                        </template>
                    </el-table-column>
                     <el-table-column
                        label="操作"
                        align="center">
                        <template slot-scope="scope">
                              <span v-show="scope.row.Flag==1&&scope.row.State==1?true:false" class="el-icon-circle-close" style="vertical-align:middle;color:#1677FF"></span><el-link style="vertical-align:top;"  @click="Rejectconfidentiality(scope.row.Id)" v-show="scope.row.Flag==1&&scope.row.State==1?true:false">驳回</el-link>
                        </template>
                    </el-table-column>
           </el-table>
          <pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageSize"
            @pagination="getList"
          >
          </pagination>
        </div>
    </div>
</template>
<script>
import treedata from "@/components/treedata";
import api from "../../../api/Grantsandmilestones"
import {mapState} from "vuex";
import pagination from "../../../components/Pagination/index.vue"
export default {
    data(){
        return{
          tableData: [],
          total:0,
          listQuery: {
            pageNum: 1,
            pageSize: 6,
          },
          srcList:[]
        }
    },
  components: {
      treedata,
      pagination
  },
  created(){
      this.GetPagesecrecylist()          
  },
  computed: mapState(["projectId"]),
  methods:{
    getList(val){//分页组件事件
      this.listQuery.pageNum=val.page
      this.GetPagesecrecylist()
    },
    Rejectconfidentiality(Id){
        let parames={
           Id:Id,
    }
    api.RejectConfidentialityData(parames).then(res=>{
         console.log(res);
         if(res.data.Status==1){
          this.$message({
            message: res.data.Message,
            type: 'success'
          });
          this.GetPagesecrecylist()   
         }else{
           alert(res.data.Message)
         }
      })
    },
    vbs(img){
     this.srcList=[]
     this.srcList.push(img)
    },
    GetPagesecrecylist(){
      let parames={
        Key:"",
        TopicId:this.projectId,
        PageIndex: this.listQuery.pageNum*1-1*1,
        PageSize: this.listQuery.pageSize,
      }
      api.GetPagesecrecyData(parames).then(res=>{
             console.log(res);
             if(res.data.Status==1){
                this.tableData=res.data.Entity
                this.total=res.data.TotalRowsCount
             }else{
               alert(res.data.Message)
             }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.Lifesaving{
    width: 100%;
    height: 100%;
    display: flex;
    .Lifesaving_left{
        width: 260px;
        height: 94%;
        margin-top: 2%;
        border-radius: 10px;
        box-sizing: border-box;
        background: white;
    }
    .Lifesaving_right{
        width: 93%;
        height: 92%;
        margin-left: 20px;
        margin-top: 2%;
        background: #FFFFFF;
        padding: 1% 1% 0 1%;
        margin-right: 1%;
        border-radius: 10px;
        .span1{
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #67c23a;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 6px;
        }
         .span2{
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #f56c6c;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 6px;
        }
    }
    ::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 3px;
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #1677ff;
}
}

</style>
